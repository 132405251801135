<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="p-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar mr-2">
          <i class="fas fa-user"></i>
        </div>
        <span>{{username}} [{{role}}]</span>
        <i class="fas fa-caret-down ml-2"></i>
      </CHeaderNavLink>
    </template>
    <CDropdownHeader tag="div" class="text-center alert-pp text-pp">
      <strong>ตั้งค่าบัญชี</strong>
    </CDropdownHeader>
    <CDropdownItem @click="isChangeProfile = !isChangeProfile">
      <i class="fas fa-user-cog"></i> แก้ไขข้อมูลส่วนตัว
    </CDropdownItem>
    <CDropdownItem @click="isChangePassword = !isChangePassword">
      <i class="fas fa-key"></i> เปลี่ยนรหัสผ่าน
    </CDropdownItem>
    <CDropdownItem @click="logout">
      <i class="fas fa-sign-out-alt"></i> ออกจากระบบ
    </CDropdownItem>
    <ChangePasswordModal :is-show="isChangePassword" @close="isChangePassword = false" />
    <EditProfileModal :is-show="isChangeProfile" @close="isChangeProfile = false" />
  </CDropdown>
</template>

<script>
import ChangePasswordModal from '@/views/components/ChangePasswordModal'
import EditProfileModal from '@/views/components/EditProfileModal'

export default {
  name: 'TheHeaderDropdownAccnt',
  components: {
    ChangePasswordModal,
    EditProfileModal
  },
  data () {
    return { 
      isChangePassword: false,
      isChangeProfile: false
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.userProfile
    },
    username() {
      return this.UserInfo?.username
    },
    role() {
      return this.UserInfo?.role
    }
  },
  methods: {
    logout() {
      console.log('logout!')
      // Auth.logout()
      this.$router.push({name: 'Logout'})
    }
  }
}
</script>

<style scoped>
.c-avatar {
  background-color: #F4BA1B;
  color: #BD3217;
}
</style>
