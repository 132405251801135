<template>
  <CFooter :fixed="false">
    <div>
      <span class="ml-1">&copy;{{new Date().getFullYear()}} www.roselottokey.com</span>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
<style lang="scss">
.c-footer {
  background-color: #E7A103;
  color: #FFFFFF;
}
</style>
