<template>
  <div class="agent-profile py-2">
    <div class="text-center text-clock py-1">{{ datetime }}</div>
    <div class="p-3 d-flex align-items-center justify-content-center">
      <div class="p-icon d-flex align-items-center justify-content-center">
        <i class="fas fa-user fa-4x"></i>
      </div>
    </div>
    <div class="d-flex justify-content-between px-2 py-1">
      <span>ชื่อ</span>
      <span>{{ UserInfo.firstName }} {{ UserInfo.lastName }}</span>
    </div>
    <div class="d-flex justify-content-between px-2 py-1">
      <span>Username</span>
      <span>{{ UserInfo.username }}</span>
    </div>
    <div class="d-flex justify-content-between px-2 py-1">
      <span>เครดิต</span>
      <span>{{ Balance }}</span>
    </div>
    <div class="d-flex justify-content-between px-2 py-1">
      <span>สมาชิก</span>
      <span>{{ Member | amountFormat(0) }}</span>
    </div>
    <div class="d-flex justify-content-between px-2 py-1">
      <span>ออนไลน์</span>
      <span>{{ Online | amountFormat(0, '0') }}</span>
    </div>

    <!-- <div class="text-center text-clock py-1">{{ datetime }}</div> -->
  </div>
</template>
<script>
import moment from '@/helpers/moment'
import AppConfig from '@/configs/app.config.js'

export default {
  name: 'AgentProfile',
  data() {
    return {
      datetime: moment().add(543, 'y').format('D MMMM YYYY, HH:mm:ss'),
      TimeInterval: null,
      AccountInterval: null,
      isShowAlertMessage: false
    }
  },
  computed: {
    UserInfo() {
      return this.$store.state.userProfile
    },
    Balance() {
      return this.$store.state.account.balance.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    Member() {
      return this.$store.state.account.member
    },
    Online() {
      return this.$store.state.account.online
    }
  },
  mounted() {
    this.$store.dispatch('reloadAccount')
    this.AccountInterval = setInterval(()=>{
      console.log('reload account')
      const isLoggedIn = localStorage.getItem(AppConfig.key.LoggedIn)
      console.log('isLoggedIn', isLoggedIn)
      if(isLoggedIn) {
        this.$store.dispatch('reloadAccount')
      }else{
        clearInterval(this.AccountInterval)
      }
    }, 60000)

    this.TimeInterval = setInterval(()=>{
      this.datetime = moment().add(543, 'y').format('D MMMM YYYY, HH:mm:ss')
    }, 1000)
  },
  beforeDestroy() {
    console.log('beforeDestroy!')
    clearInterval(this.AccountInterval)
    clearInterval(this.TimeInterval)
  }
}
</script>
<style lang="scss" scoped>
.agent-profile {
  font-size: 90%;
  border-bottom: 2px solid #fff;
  color: #fff;

  .text-clock {
    color: #FBB404;
  }

  .p-icon {
    border: 2px solid #EEEEEE;
    display: block;
    width: 100px;
    height: 100px;
    border-radius: 50px;
  }
}
</style>
