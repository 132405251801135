<template>
  <CHeader :fixed="true" with-subheader :light="false">
    <CHeaderNav class="d-md-down-none mr-auto">
      <div class="app-logo d-flex justify-content-center">
        <router-link to="/">
          <img src="@/assets/nologo.png">
        </router-link>
      </div>
      <TheGlobalMarket class="ml-4" />
      <TheGlobalRound />
    </CHeaderNav>
    <CHeaderNav class="mr-4 ml-auto">
      <TheHeaderBalance class="mr-2" />
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
  </CHeader>
</template>

<script>
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'
import TheHeaderBalance from './TheHeaderBalance'
import TheHeaderTime from './TheHeaderTime'
import GlobalMarketModal from '@/views/components/GlobalMarketModal'
import GlobalRoundModal from '@/views/components/GlobalRoundModal'
import TheGlobalMarket from './TheGlobalMarket'
import TheGlobalRound from './TheGlobalRound'

import SystemConfigService from '@/services/SystemConfigService'
import MarketService from '@/services/MarketService'

import moment from '@/helpers/moment'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderBalance,
    TheHeaderTime,
    TheHeaderDropdownAccnt,
    GlobalMarketModal,
    GlobalRoundModal,
    TheGlobalMarket,
    TheGlobalRound
  },
  data() {
    return {
      isShowMarketModal: false,
      isShowRoundModal: false,
      isLoading: false
    }
  },
  computed: {
    globalMarket() {
      return this.$store.state.globalMarket
    },
    currentGroupId() {
      return this.globalMarket.groupId
    },
    currentMarketId() {
      return this.globalMarket.marketId
    },
    groupTitle() {
      const group = this.$store.state.marketGroups.find(group=>group._id===this.currentGroupId)
      return group?.groupTitle
    },
    marketTitle() {
      const market = this.$store.state.markets.find(market=>market._id===this.currentMarketId)
      return market?.marketTitle
    },
    markets() {
      return this.$store.state.markets
    },
    roundDate() {
      if(!this.globalMarket.round.roundDate)
        return '--/--/----'

      return moment(this.globalMarket.round.roundDate.date).format("DD/MM/YYYY")
    },
    marketIcon() {
      return this.globalMarket?.imageIcon
    }
  },
  watch: {
    currentGroupId() {
      if(!this.currentMarketId) {
        this.setStartMarketId()
      }
    },
    currentMarketId() {
      this.$store.commit('updateGlobalMarket', {
        round: {
          roundId: null,
          roundDate: null
        }
      })
      this.getMarketRound()
    },
    markets(n, o) {
      if(o.length === 0 && !this.currentMarketId) {
        this.setStartMarketId()
      }
    }
  },
  methods: {
    closeMarketModal() {
      this.isShowMarketModal = false
    },
    closeRoundModal() {
      this.isShowRoundModal = false
    },
    setStartMarketId() {
      const marketsInGroup = this.markets.filter((market)=>{
        return market.groupId === this.currentGroupId
      })

      if(marketsInGroup.length) {
        this.$store.commit('updateGlobalMarket', {
          marketId: marketsInGroup[0]._id,
          marketType: marketsInGroup[0].marketType
        })
      }
    },
    getMarketRound() {
      this.isLoading =true
      MarketService.getRound(this.currentMarketId)
      .then((response)=>{
        if(response.success) {
          // console.log(response.data)
          if(response.data) {
            this.$store.commit('updateGlobalMarket', {
              round: {
                roundId: response.data._id,
                roundDate: response.data.roundDate
              }
            })
          }
        }else{
          throw new Error(response?.message)
        }
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
  }
}
</script>
<style lang="scss">
.c-header {
  background-color: #E7A103;
  border-bottom: 1px solid #e9ecef;
  z-index: 1031 !important;

  .app-logo {
    width: 256px;

    a {
      img {
        width: 100%;
      }
    }
  }

  .c-header-nav-link {
    cursor: pointer;
    color: #FFFFFF !important;
  }

  .dropdown-menu.show {
    border: 1px solid #3D1766;
  }
}
</style>
